import { AfterViewInit, Component, ElementRef, OnDestroy, signal, viewChild, inject, computed, Signal } from '@angular/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { ResolutionService } from '../resolution.service';
import { SignalsStoreService } from '../signals-store.service';

@Component({
    selector: 'app-shop-slider-banner',
    imports: [],
    templateUrl: './shop-slider-banner.component.html',
    styleUrl: './shop-slider-banner.component.scss'
})
export class ShopSliderBannerComponent implements OnDestroy, AfterViewInit {

  //#region Services

  #resolutionService = inject(ResolutionService);
  #signalsStoreService = inject(SignalsStoreService);

  //#endregion

  //#region Properties

  shopSlider = viewChild.required<ElementRef>('shopSlider');

  slides = signal<{ text: string; title: string; img: Signal<string> }[]>([]);

  isMobile = computed(() => this.#resolutionService.isMobile());

  isSmallMobile = computed(() => this.#resolutionService.isSmallMobile());

  slider: KeenSliderInstance | any = null;

  dotHelper = signal<number[]>([]);

  currentSlide = 0;

  isShopBannerHidden = computed(() => this.#signalsStoreService.isShopBannerHidden());

  //#endregion

  //#region Constructors

  ngAfterViewInit() {
    this.#getSlides();
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
  }

  //#endregion

  //#region Methods

  #setUpDefaultSlider() {

    if (!(this.shopSlider()?.nativeElement)) return;
    this.slider = new KeenSlider(this.shopSlider().nativeElement, {
      initial: this.currentSlide,
      renderMode: 'performance',
      loop: true,
      drag: false,
      slideChanged: s => this.currentSlide = s.track.details.rel
    }, [
      (slider) => {
        let timeout: NodeJS.Timeout | null = null;
        let mouseOver = false
        function clearNextTimeout() {
          if (timeout)
            clearTimeout(timeout);
        }
        function nextTimeout() {

          if (timeout)
            clearTimeout(timeout);

          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 1000 * 5)
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on("dragStarted", clearNextTimeout)
        slider.on("animationEnded", nextTimeout)
        slider.on("updated", nextTimeout)
      }
    ]);

    if (this.slider.track?.details?.slides?.length) {
      this.dotHelper.set([
        ...Array(this.slider.track.details.slides.length)
      ]);
    }
  }

  #getSlides() {

    const version = 6; // Importat to change this version number when updating the images
    this.slides.set([
      {
        title: '<p class="m-0 text-white">Your local food connection just got a glow-up.</p>',
        text: `<p class="m-0"><span class="text-white">Questions about your account or how to navigate shopping on the new site? Read our FAQs or reach out to </span> <span class="text-primary" >info@theproducebox.com!</span></p>`,
        // img: '/assets/images/shop-slider/slide1.jpeg'
        img: computed(() => {
          const tablet = this.isMobile();
          const mobile = this.isSmallMobile();
          const folder = tablet ? 'Tablet' : mobile ? 'Mobile' : 'Desktop';
          return `/assets/images/shop-slider/${folder}/1.png?v=${version}`
        })
      },
      {
        title: '<p class="m-0 text-white">Please check your subscription preferences!</p>',
        text: `<p class="m-0 text-white">If you're subscribed to an item or box, please check that your subscription preferences and any planned skip carried over to your profile on the new site.</p>`,
        img: computed(() => {
          const tablet = this.isMobile();
          const mobile = this.isSmallMobile();
          const folder = mobile ? 'Mobile' : tablet ? 'Tablet' : 'Desktop';
          return `/assets/images/shop-slider/${folder}/2.png?v=${version}`
        })
      },
      {
        title: '<p class="m-0 text-white">Meet your Farmers and Artisans</p>',
        text: '<p class="m-0 text-white">Browse Producer Profiles and connect to the hardworking local who bring you your food. Now including pictures!</p>',
        img: computed(() => {
          const tablet = this.isMobile();
          const mobile = this.isSmallMobile();
          const folder = mobile ? 'Mobile' : tablet ? 'Tablet' : 'Desktop';
          return `/assets/images/shop-slider/${folder}/3.png?v=${version}`
        })
      },
      {
        title: `<p class="m-0 text-white"><span class="text-primary">We're here to help.</span> | info@theproducebox.com</p>`,
        text: `<p class="m-0 text-white">Enjoy exploring our online farmers market, and don't hesitate to reach out if you need help placing your order this weekend!</p>`,
        img: computed(() => {
          const tablet = this.isMobile();
          const mobile = this.isSmallMobile();
          const folder = mobile ? 'Mobile' : tablet ? 'Tablet' : 'Desktop';
          return `/assets/images/shop-slider/${folder}/4.png?v=${version}`
        })
      }
    ]);

    setTimeout(() => {
      this.#setUpDefaultSlider();
    });
  }

  //#endregion
}
